<template>
  <vx-card no-shadow>
    <vs-input
      class="w-full mb-base"
      type="password"
      label-placeholder="原密码"
      v-model="current_password"
      :warning="!current_password"
      warning-text="原始密码必须输入"
    />
    <vs-input
      class="w-full mb-base"
      type="password"
      label-placeholder="新密码"
      v-model="password"
      :warning="!password && password!=current_password"
      warning-text="新密码必须输入"
    />
    <vs-input
      class="w-full mb-base"
      type="password"
      label-placeholder="确认密码"
      v-model="password_confirmation"
      :warning="password && (password_confirmation!=password)"
      warning-text="确认密码与新密码不同"
    />

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="updatePWD()">保存修改</vs-button>
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">重置</vs-button> -->
    </div>
  </vx-card>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],

  data() {
    return {
      current_password: "",
      password: "",
      password_confirmation: ""
    };
  },
  computed: {},
  methods: {
    updatePWD() {
      if (
        this.current_password &&
        this.password &&
        this.password == this.password_confirmation
      ) {
        let payload = {
          vm: this,
          user: {
            current_password: this.current_password,
            password: this.password,
            password_confirmation: this.password_confirmation
          }
        };
        this.$store.dispatch("changePassword", payload);
      }
    }
  }
};
</script>
