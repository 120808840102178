<template>
  <vx-card no-shadow>

    <!-- Info -->
    <vs-input class="w-full mb-base" label-placeholder="移动电话" v-model="user.mobile" disabled></vs-input>
    <vs-input class="w-full mb-base" label-placeholder="姓名" v-model="user.name"></vs-input>

    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="user.image_url" size="70px" class="mr-4 mb-4" />
      <div>
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="$refs.files.click()">上传图片</vs-button>
        <p class="text-sm mt-2">只允许上传JPG, GIF 或者 PNG. 最大尺寸不超过800kB</p>

        <!--file upload-->
        <input
          type="file"
          id="files"
          ref="files"
          @change="fileSelected()"
          style="visibility:hidden"
        />
      </div>
    </div>

    <!-- <vs-alert icon-pack="feather" icon="icon-info" class="h-full my-4" color="warning">
      <span>Your account is not verified. <a href="#" class="hover:underline">Resend Confirmation</a></span>
    </vs-alert>-->

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" @click="saveChange()">保存修改</vs-button>
      <vs-button class="ml-4 mt-2" type="border" color="warning">重置</vs-button>
    </div>
  </vx-card>
</template>

<script>
import axios from "axios";

import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState } from "vuex";

export default {
  mixins: [baseFun],
  data() {
    return {
      user: null,
    };
  },
  computed: {
    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "group_assignments",
      "workouts",
      "workout_items",
      "activeUser",
      "activeOrganization",
      "comments"
    ])
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.activeUser));
  },

  methods:{
    fileSelected() {
      let self = this;
      let file = this.$refs.files.files[0];

      let formData = new FormData();
      formData.append("file", file);

      let uploadPath = "";
      if (file.type.indexOf("image/") == 0) uploadPath = "/upload/image";
      if (file.type.indexOf("video/") == 0) uploadPath = "/upload/file";
      if (!uploadPath) {
        this.$vs.notify({
          title: "错误",
          text: "文件类型不支持",
          color: "danger",
          position: "top-right"
        });
        this.$refs.files.value = "";
        return;
      }

      axios
        .post(uploadPath, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function(resp) {
          // console.log(resp.data)
          self.user.image_url = resp.data.url;
          // self.activeUser.image_url = resp.data.url;
          self.$refs.files.value = "";

          // console.log("SUCCESS!!");
        })
        .catch(function(err) {
          // console.log("FAILURE!!" + err);
          self.$vs.notify({
            title: "错误",
            text: err,
            color: "danger",
            position: "top-right"
          });
          self.$refs.files.value = "";
        });
    },

    saveChange(){
      this.$store.dispatch("updateUser", {user:this.user, vm:this});
    }
  }
};
</script>
