<template>
  <vs-tabs position="left" id="profile-tabs" :value="activeIdx">
    <!-- general -->
    <vs-tab icon-pack="feather" icon="icon-user" label="基础信息">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <profile-general />
      </div>
    </vs-tab>

    <!-- password -->
    <vs-tab icon-pack="feather" icon="icon-lock" label="修改密码">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <profile-change-password />
      </div>
    </vs-tab>

    <!-- organization -->
    <vs-tab icon-pack="feather" icon="icon-users" label="组织">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <profile-organization />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import ProfileGeneral from "./ProfileGeneral.vue"
import ProfileChangePassword from "./ProfileChangePassword.vue"
import ProfileOrganization from "./ProfileOrganization.vue"

export default {
  props:["fun"],

  data(){
    return {}
  },

  computed:{
    activeIdx(){
      const funs = {
        "baseInfo": 1,
        "changePWD": 2,
        "orgInfo": 3
        };
      let index = funs[this.fun?this.fun:"baseInfo"];
      if (!index) index = 1;
      return index-1;
    }
  },

  components:{
    ProfileGeneral,
    ProfileChangePassword,
    ProfileOrganization
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
